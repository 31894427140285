import { Container, Row, Col } from 'react-bootstrap';
import { FaLinkedin, FaGithub, FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';
import './FollowMe.css';

function FollowMe() {
    return (
        <Container className="my-5" id="follow-me">
            <Row>
                <Col className="text-center">
                    <h2>Follow Me</h2>
                    <div className="follow-me">
                        <a href="https://www.linkedin.com/in/nissim-amira/" target="_blank" rel="noopener noreferrer" className="social-links"><FaLinkedin /></a>
                        <a href="https://github.com/NissimAmira" target="_blank" rel="noopener noreferrer" className="social-links"><FaGithub /></a>
                        <a href="https://www.instagram.com/amiranissim?igsh=bDBvcnpyM2U2c20z&utm_source=qr" target="_blank" rel="noopener noreferrer" className="social-links"><FaInstagram /></a>
                        <a href="https://www.facebook.com/nisim.amira?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="social-links"><FaFacebook /></a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
 export default FollowMe;