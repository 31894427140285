import React, { useState } from "react";
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import './ContactMe.css';
import emailjs from 'emailjs-com';

function ContactMe() {

    const [formData, setFormData] = useState({ name: '', email: '', message: '' });

    const handleChange = (e) => {
        if (submissionStatus) setSubmissionStatus(null);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [submissionStatus, setSubmissionStatus] = useState(null);


    const handleSubmit = async (e) => {
        e.preventDefault();
        emailjs.sendForm('service_ncrv4nd', 'template_zbqohy3', e.target, 'k2qeH2VSQQmD0hh4V')
            .then((result) => {
                console.log(result.text);
                setSubmissionStatus('success');
            }, (error) => {
                console.log(error.text);
                setSubmissionStatus('failure');
            });
        setFormData({ name: '', email: '', message: '' }); // Clear form after sending
    };

    return (
        <Container className="my-5" id="contact-me">
            <Row>
                <Col>
                    <h2 className="text-center">Contact Me</h2>
                    <div>
                        {submissionStatus === 'success' && <div className="alert alert-success">Form submitted successfully!</div>}
                        {submissionStatus === 'failure' && <div className="alert alert-danger">Submission failed. Please try again.</div>}
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Name *</Form.Label>
                            <Form.Control type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address *</Form.Label>
                            <Form.Control type="email" name="email" placeholder="Enter email" value={formData.email} onChange={handleChange} required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicMessage">
                            <Form.Label>Message *</Form.Label>
                            <Form.Control as="textarea" type="text" name="message" placeholder="Enter your message..." rows={6} value={formData.message} onChange={handleChange} required/>
                        </Form.Group>
                        <Button variant="outline-light" type="submit" id="formSubmitButton">
                            Send
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactMe;