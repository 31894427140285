import { Container, Row, Col, Button } from 'react-bootstrap';
import './AboutMe.css';

function AboutMe() {
    return (
        <Container className="my-5" id="about-me">
            <Row>
                <Col>
                    <h2 className="mb-3 text-center">About Me</h2>
                    <ul>
                        <li>Full-Stack Web Developer</li>
                        <li>Automation Engineer @ Findings.co</li>
                        <li>BA in Computer Science @ HIT</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutMe;