import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Header.css';


function Header() {

    const [expanded, setExpanded] = useState(false);
    const toggleNavbar = () => setExpanded(!expanded);


    return (
        <Navbar expand="lg" fixed="top" className="custom-navbar" data-bs-theme="dark" expanded={expanded}>
            <div className="brand-container">
                <Navbar.Brand id="nav-brand" href="#top-section">Nissim Amira</Navbar.Brand>
            </div>
            <div className="toggler-container">
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar}/>
            </div>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link href="#about-me" offset={-70} onClick={() => setExpanded(false)}>About Me</Nav.Link>
                        <Nav.Link href="#my-skills" offset={-70} onClick={() => setExpanded(false)}>Skills</Nav.Link>
                        <Nav.Link href="#follow-me" offset={-70} onClick={() => setExpanded(false)}>Follow Me</Nav.Link>
                        <Nav.Link href="#contact-me" offset={-70} onClick={() => setExpanded(false)}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;
