import React from 'react';
import './ProgressCircles.css';

function ProgressCircles({ filledCount }) {
    const circles = [];
    for (let index = 0; index < 10; index++) {
        // Check if the current circle should be filled
        const isFilled = index < filledCount;
        let circleClasses;

        if (isFilled){
            // Add the 'circle' class and 'filled' class if isFilled is true
            circleClasses = `circle ${isFilled ? 'filled' : ''}`;
        } else {
            circleClasses = `circle`;
        }

        // Push the circle div to the circles array
        circles.push(
            <div key={index} className={circleClasses} />
        );

    }
    return(
        <div className="circle-container">
            { circles }
        </div>
    )
}

export default ProgressCircles;