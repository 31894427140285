import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import ProgressCircles from "../Components/ProgressCircles";
import './MySkills.css';

function MySkills() {
    return (
        <Container className="my-5" id="my-skills">
            <Row>
                <h2 className="mb-3 text-center">My Skills</h2>
                <Col className="skill-column" lg={5} md={6} sm={8}>
                    <p className="skill-name">JavaScript</p>
                    <ProgressCircles filledCount={9} />
                    <p className="skill-name">TypeScript</p>
                    <ProgressCircles filledCount={9} />
                    <p className="skill-name">C / C++</p>
                    <ProgressCircles filledCount={7} />
                    <p className="skill-name">Java</p>
                    <ProgressCircles filledCount={7} />
                </Col>
                <Col className="skill-column" lg={5} md={6} sm={8}>
                    <p className="skill-name">React</p>
                    <ProgressCircles filledCount={9} />
                    <p className="skill-name">HTML</p>
                    <ProgressCircles filledCount={9} />
                    <p className="skill-name">CSS</p>
                    <ProgressCircles filledCount={8} />
                    <p className="skill-name">Python</p>
                    <ProgressCircles filledCount={7} />
                </Col>
            </Row>
        </Container>
    );
}


export default MySkills;