import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './TopSection.css'

function TopSection() {
    return (
        <Container className="my-5 p-3 rounded" id="top-section">
            <Row>
                <Col className="text-center">
                    <Container>
                        <h1>Nissim Amira</h1>
                        <h2>Developer Portfolio</h2>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default TopSection;
