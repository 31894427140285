import React, { useState } from 'react';
import './FloatingBubble.css';

function FloatingBubble({ imgSrc }) {
    const [position, setPosition] = useState({ left: 50, top: 50 });

    const moveBubble = () => {
        const x = Math.random() * window.innerWidth;
        const y = Math.random() * window.innerHeight;
        setPosition({ left: x, top: y });
    };

    return (
        <div id="floating-bubble" onLoad={moveBubble} onMouseEnter={moveBubble} style={{ left: position.left, top: position.top }}>
            <img src={imgSrc} alt="FloatingBubble" />
        </div>
    );
}

export default FloatingBubble;
