import React from 'react';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';
import './App.css'
import Header from './sections/Header';
import TopSection from './sections/TopSection';
import AboutMe from './sections/AboutMe';
import MySkills from './sections/MySkills';
import MyProjects from './sections/MyProjects';
import ContactMe from './sections/ContactMe';
import FollowMe from './sections/FollowMe';
import FloatingBubble from "./Components/FloatingBubble";
import ProgressCircles from "./Components/ProgressCircles";

function App() {
    return (
        <div>
            <Header id="top"/>
            <div style={{ paddingTop: '56px' }}> {/* Offset for fixed navbar */}
                <TopSection />
                <AboutMe />
                <MySkills />
                <FollowMe />
                <ContactMe />
            </div>
            <FloatingBubble imgSrc="logo.png" />
            <FloatingBubble imgSrc="theLimit.jpeg" />
        </div>
    );
}

export default App;
