import { Container, Row, Col, Card } from 'react-bootstrap';

function MyProjects() {
    return (
        <Container className="my-5" id="my-projects">
            <Row>
                <Col>
                    <h2>My Projects</h2>
                    {/* Example project card */}
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Project Title</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    {/* Repeat for other projects */}
                </Col>
            </Row>
        </Container>
    );
}

export default MyProjects;